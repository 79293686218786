<script>
import {defineComponent} from 'vue'
import {Capacitor} from '@capacitor/core'
import {Preferences} from '@capacitor/preferences'
import {StatusBar, Style} from '@capacitor/status-bar'
import GlobalComponents from '@/components/GlobalComponents.vue'
import VerificadorDeVersao from '@/components/funcionais/VerificadorDeVersao.vue'
export default defineComponent({
  name: 'App',
  components: {GlobalComponents, VerificadorDeVersao},
  async mounted() {
    const {value: theme} = await Preferences.get({key: 'cache_theme'})
    if (theme != null) this.$vuetify.theme.dark = theme === '1';
  },
  methods: {
    onThemeChange() {
      document.querySelector('meta[name="theme-color"]').setAttribute('content',  this.$vuetify.theme.dark ? '#272727' : '#1976D2');
      if (Capacitor.isNativePlatform()) StatusBar.setStyle({style: Style.Dark})
      if (Capacitor.getPlatform() === 'android') StatusBar.setBackgroundColor({color: (this.$vuetify.theme.dark ? '#272727' : '#1976D2')})
      Preferences.set({key: 'cache_theme', value: (this.$vuetify.theme.dark ? '1' : '0')})
    },
  },
  computed: {
    darkMode() {return this.$vuetify.theme.dark},
  },
  watch: {
    darkMode() {this.onThemeChange()},
  },
})
</script>

<template>
  <v-app>
    <router-view></router-view>
    <GlobalComponents />
    <VerificadorDeVersao />
  </v-app>
</template>

<style>
.v-application--is-ltr .v-data-footer__select div.v-select {
  margin-left: 4px;
}
.v-application--is-ltr div.v-data-footer__select {
  margin-right: auto;
  margin-left: 6px;
}
.v-application--is-ltr div.v-data-footer__pagination {
  margin-left: 6px;
  margin-right: 0;
}
thead.v-data-table-header th {
  white-space: nowrap;
}
.v-dialog:not(.v-dialog--fullscreen) {
  margin: .8rem !important;
}
</style>
