import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { pinia } from '@/plugins/pinia'
import { checkVersion } from '@/plugins/persistance'
import { restoreSavedSession } from '@/store/Session'
import '@babel/polyfill'
import '@/assets/global.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './plugins/vue-signature-pad'
import './plugins/vue-the-mask'
import './plugins/apexcharts'

Vue.config.productionTip = false

try {
  await checkVersion()
  await restoreSavedSession(pinia)
} finally {
  new Vue({
    pinia,
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}