import axios from 'axios'
import { useSessionStore } from '@/store/Session'
import { pinia } from '@/plugins/pinia'

/**
 * Cria uma instância Axios com a possibilidade de tratar o erro com uma mensagem recebida via callback.
 * @param {function(string)} errorCallback - Callback com mensagem de erro no primeiro parametro.
 * @return AxiosInstance
 */
export const createWebClient = (errorCallback) => {
  const axiosClient = axios.create({
    baseURL: 'https://novo.conselho.net.br/api', //default: '/api'
    timeout: 90000,
    timeoutErrorMessage: 'timeout'
  })
  const session = useSessionStore(pinia)

  const beforeSend = conf => {
    if (session.token) conf.headers.Authorization = `Bearer ${session.token}`; //Authorization Header
    return conf;
  }
  axiosClient.interceptors.request.use(beforeSend, error => Promise.reject(error))

  const onError = error => {
    if (error.response?.status === 410) session.logout()
    if (navigator && navigator.onLine !== true) errorCallback('Parece que você está sem conexão com a internet.')
    else if (error.message === 'timeout') errorCallback('O processamento está tomando mais tempo do que o permitido. Sua conexão está ruim. Estabilidade e velocidade insuficiente para manter uma conexão.')
    else if (error.response?.data && error.response?.status !== 418) {
      if (typeof error.response.data === 'object' && error.response.data instanceof Blob && error.response.data.type === 'application/json') {
        //Erroneamente recebeu uma resposta em JSON mas acabou interpretando como Blob, reajustando isso.
        const fileReader = new FileReader()
        fileReader.onload = () => {
          const data = JSON.parse(fileReader.result)
          if (data?.mensagem) errorCallback(data.mensagem)
        }
        fileReader.readAsText(error.response.data)
      }
      else if (error.response.data.mensagem) errorCallback(error.response.data.mensagem)
    }
    else if (error.code === 'ECONNABORTED') errorCallback('O processamento foi cancelado pelo dispositivo, parece haver um problema de conexão.')
    else if (error.code === 'ERR_NETWORK') errorCallback('Seu dispositivo fracassou ao tentar estabelecer conexão. Você pode estar com a conexão ruim ou o servidor rejeitou a comunicação após perceber alguma falha.')
    else if (error.message) errorCallback(error.message)
    else errorCallback('Ocorreu uma falha ao tentar realizar o processamento, a conexão pode estar ruim ou o serviço pode estar indisponível.')
    return Promise.reject(error)
  }
  axiosClient.interceptors.response.use(res => res, onError)

  return axiosClient
}

export const http = createWebClient(message => alert(message))
export const httpSilent = createWebClient(() => null)
export default http
