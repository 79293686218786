
const routes = [
  {
    path: '/auth',
    component: () => import('../layouts/AuthLayout.vue'),
    meta: { requiresNoAuth: true },
    children: [
      { path: 'login', name: 'login', alias: '/login', component: () => import('../views/LoginView.vue') },
    ],
  },

  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: '', name: 'home', alias: '/home', component: () => import('../views/HomeView.vue') },
      { path: 'perfil', name: 'perfil', component: () => import('../views/perfil/MeuPerfil.vue') },
      { path: 'assinatura', name: 'assinatura', component: () => import('../views/perfil/TrocarAssinatura.vue') },
      { path: 'pesquisar', name: 'pesquisar', component: () => import('../views/cadastro/PesquisarCadastro.vue'), meta: {title: 'Pesquisar Cadastro'} },
      { path: 'pesquisarauto', name: 'pesquisarauto', component: () => import('../views/fiscalizacao/PesquisarAuto.vue'), meta: {title: 'Pesquisar Autuação'} },
      { path: 'cadastro/:id', name: 'cadastro-geral', props: true, component: () => import('../views/cadastro/CadastroGeral.vue'), meta: {title: 'Ficha Cadastral'} },
      { path: 'minhaperformance', name: 'minha-performance', component: () => import('../views/fiscalizacao/MinhaPerformance.vue'), meta: {title: 'Minha Performance'} },
      { path: 'meusautos', name: 'meus-autos', component: () => import('../views/fiscalizacao/MeusAutos.vue'), meta: {title: 'Meus Autos'} },
      { path: 'lavrarauto/:id?', name: 'lavrar-auto', component: () => import('../views/fiscalizacao/lavratura/LavrarAuto.vue'), meta: {title: 'Lavrar Auto'} },
      { path: 'lavrarconstatacao/:id?', name: 'lavrar-constatacao', component: () => import('../views/fiscalizacao/lavratura/LavrarConstatacao.vue'), meta: {title: 'Lavrar Constatação'} },
      { path: 'lavrarnotificacao/:id?', name: 'lavrar-notificacao', component: () => import('../views/fiscalizacao/lavratura/LavrarNotificacao.vue'), meta: {title: 'Lavrar Notificação'} },
      { path: 'lavrarinfracao/:id?', name: 'lavrar-infracao', component: () => import('../views/fiscalizacao/lavratura/LavrarInfracao.vue'), meta: {title: 'Lavrar Infração'} },
      { path: 'autoconstatacao/:id', name: 'auto-constatacao', props: true, component: () => import('../views/fiscalizacao/auto/AutoConstatacao.vue'), meta: {title: 'Auto de Constatação'} },
      { path: 'autonotificacao/:id', name: 'auto-notificacao', props: true, component: () => import('../views/fiscalizacao/auto/AutoNotificacao.vue'), meta: {title: 'Auto de Notificação'} },
      { path: 'autoinfracao/:id', name: 'auto-infracao', props: true, component: () => import('../views/fiscalizacao/auto/AutoInfracao.vue'), meta: {title: 'Auto de Infração'} },
      { path: 'assinarconstatacao/:id', name: 'assinar-constatacao', props: true, component: () => import('../views/fiscalizacao/assinatura/AssinarConstatacao.vue'), meta: {title: 'Assinar constatação'} },
      { path: 'assinarnotificacao/:id', name: 'assinar-notificacao', props: true, component: () => import('../views/fiscalizacao/assinatura/AssinarNotificacao.vue'), meta: {title: 'Assinar notificação'} },
      { path: 'assinarinfracao/:id', name: 'assinar-infracao', props: true, component: () => import('../views/fiscalizacao/assinatura/AssinarInfracao.vue'), meta: {title: 'Assinar infração'} },
      { path: 'suporte', name: 'suporte', component: () => import('../views/SuporteView.vue') },
      { path: 'updatenotes', name: 'update-notes', component: () => import('../views/NotasDeAtualizacao.vue') },
    ]
  },

  {
    path: '*',
    name: 'notfound',
    component: () => import('../views/ErrorNotFound.vue'),
  },
]

export default routes