import JsonPackage from '../../package.json'
import {Preferences} from '@capacitor/preferences'

/**
 * Limpa o cache se a versão do aplicativo mudar
 */
export const checkVersion = async () => {
  const {value: x} = await Preferences.get({key: 'version'})
  const {value: y} = await Preferences.get({key: 'app_name'})
  if ((!x || x !== JsonPackage.version) || (!y || y !== JsonPackage.name)) {
    await Preferences.clear()
    await Preferences.set({key: 'version', value: JsonPackage.version})
    await Preferences.set({key: 'app_name', value: JsonPackage.name})
  }
}
