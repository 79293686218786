<template>
  <Fragment>
    <v-dialog v-model="showDialog" width="24rem" persistent>
      <v-card color="error" v-if="versaoDepreciada">
        <v-card-text class="pt-4 white--text">
          <div class="text-center">
            <v-avatar size="64" color="red lighten-2">
              <v-icon size="40" color="white">mdi-alert</v-icon>
            </v-avatar>
            <p class="title">Atualize sua aplicação!</p>
          </div>
          <p class="text-justify body-1 mb-0">
            Você está usando uma versão obsoleta da aplicação e isso poderia prejudicar algumas atividades.
          </p>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-text class="pt-4">
          <div class="text-center">
            <v-avatar size="64" color="warning">
              <v-icon size="40" color="white">mdi-alert-rhombus-outline</v-icon>
            </v-avatar>
            <p class="title">Atualize sua aplicação!</p>
          </div>
          <p class="text-justify body-1 mb-0">
            Já foi lançada uma nova versão, atualize sua aplicação para usar novos recursos ou correções.
          </p>
        </v-card-text>
        <v-card-actions class="justify-center pt-0">
          <v-btn color="warning" @click="showDialog = false" small>
            <v-icon small class="mr-1">mdi-close-circle</v-icon>OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loadingData" z-index="210" :opacity=".85">
      <div class="text-center">
        <v-progress-circular indeterminate size="48"></v-progress-circular>
        <p class="mt-3 mb-0">Carregando configurações</p>
      </div>
    </v-overlay>
  </Fragment>
</template>

<script setup>
import {ref} from 'vue'
import {httpSilent} from '@/plugins/axios'
import {lt} from 'semver'
import JsonPackage from '../../../package.json'
import {Fragment} from 'vue-frag';

const showDialog = ref(false)

const loadingData = ref(true)
const versaoDepreciada = ref(null) //True se o app está fora do range da versão mínima
httpSilent.get('/versao').then(r => {
  const versaoMinima = r.data.minima
  const versaoLatest = r.data.atual

  versaoDepreciada.value = lt(JsonPackage.version, versaoMinima)
  if (versaoDepreciada.value || lt(JsonPackage.version, versaoLatest)) showDialog.value = true
}).finally(() => loadingData.value = false)
</script>
