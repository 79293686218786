<script setup>
import { Fragment } from 'vue-frag'
import { useComponentsStore } from '@/store/GlobalComponents'
import { useRoute } from 'vue-router/composables'
import { watch } from 'vue'

const componentsStore = useComponentsStore()

//Algoritmo dos prompts
const promptOnOk = (formComponent, promptId, onOk = () => {}, promptValue = null) => {
  if (!formComponent.validate()) return
  onOk(promptValue)
  componentsStore.removerPrompt(promptId)
}
const route = useRoute()
watch(route, () => componentsStore.resetarPrompts())
</script>

<template>
  <Fragment>
    <!-- Alert -->
    <div
      style="position: fixed; right: 0; top: 0; z-index: 6;"
      class="d-flex flex-column"
      :class="{'align-center': $vuetify.breakpoint.smAndDown, 'align-end': !$vuetify.breakpoint.smAndDown}"
    >
      <transition-group name="slide-x-reverse-transition" appear>
        <v-alert
          v-for="i in componentsStore.alerts"
          :key="i.id"
          :type="i.type"
          :class="{'mr-3': !$vuetify.breakpoint.smAndDown}"
          class="mt-2 mb-0 alert"
          elevation="2"
          width="20rem"
          max-width="100%"
        >{{ i.text }}</v-alert>
      </transition-group>
    </div>

    <!-- Dialog -->
    <v-dialog app persistent width="32rem" :value="componentsStore.dialogShow">
      <v-card>
        <v-alert type="warning" border="top" class="mb-0 text-justify" text :icon="false">
          <div class="d-flex align-center flex-column">
            <v-icon size="30" color="white" class="warning lighten-1 rounded-circle pa-2 mb-2">mdi-alert</v-icon>
            <p
              class="body-1 flex-grow-1 mb-0"
              style="white-space: pre-wrap; text-align: justify"
              v-html="componentsStore.dialogContent"
            ></p>
          </div>
        </v-alert>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn small depressed color="warning" @click="componentsStore.dialogShow = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loader -->
    <v-overlay :value="componentsStore.loaderShow" z-index="210" :opacity=".7">
      <div class="text-center">
        <v-progress-circular indeterminate size="48"></v-progress-circular>
        <p v-if="componentsStore.loaderText">{{ componentsStore.loaderText }}</p>
      </div>
    </v-overlay>

    <!-- Snackbar -->
    <v-snackbar
      v-for="i in componentsStore.snacks"
      :key="i.id"
      :color="i.color"
      :timeout="i.timeout"
      :value="true"
      @input="componentsStore.removeSnack(i.id)"
      min-width="8rem"
      max-width="100%"
      app
    >
      <v-icon dense class="mr-1" color="white" v-if="i.color === 'success'">mdi-check-circle</v-icon>
      {{ i.text }}
    </v-snackbar>

    <!-- Prompt -->
    <v-dialog
      v-for="(i, index) in componentsStore.prompts"
      v-model="i.show"
      :key="i.id"
      :width="i.options.width || '24em'"
      :persistent="i.options.persistent"
      @click:outside="i.options.persistent ? null : componentsStore.removerPrompt(i.id)"
    >
      <v-card>
        <v-form @submit.prevent="promptOnOk($refs.prompters[index], i.id, i.options.onOk, i.promptValue)" ref="prompters">
          <v-card-title v-if="i.options.title">{{ i.options.title }}</v-card-title>
          <v-card-text v-if="i.options.message || i.options.prompt" class="text-justify pb-1">
            <span v-if="i.options.message">{{ i.options.message }}</span>
            <v-text-field
              v-if="i.options.prompt"
              v-model="i.promptValue"
              :label="i.options.prompt.label"
              :type="i.options.prompt.type || 'text'"
              :rules="i.options.prompt.rules"
              :outlined="i.options.prompt.outlined"
              :style="{'marginTop': i.options.message ? '1rem' : '0'}"
              :dense="i.options.prompt.dense"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              v-if="i.options.cancel"
              :color="i.options.cancelColor || 'error'"
              :small="i.options.smallButtons"
              @click="componentsStore.removerPrompt(i.id, true)"
              depressed
            >{{ i.options.cancelText || 'CANCELAR' }}</v-btn>
            <v-btn
              :color="i.options.confirmColor || 'primary'"
              :small="i.options.smallButtons"
              type="submit"
              depressed
            >{{ i.options.confirmText || 'OK' }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </Fragment>
</template>
